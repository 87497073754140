<template>
    <div class="row">
        <div class="col-12">
            <div class="row justify-content-between">
                <div class="col-lg-6 pt-3 pl-4">
                    <h2 class="fw-400 font-lg d-block"><b>Attendance</b></h2>
                </div>
                <div class="form-group col-lg-5">
                    <input @change="changeDate()" v-model="date" type="date" class="form-control" required>
                </div>
            </div>
            <div class="row mt-2" v-if="!isLoad">
                <div v-if="data.length" class="col-12">
                    <div class="row">
                        <div class="card shadow rounded-lg col-md-7 border-0 pl-3 chat-wrapper scroll-bar">
                            <div class="section full mt-2 mb-2">
                                <ul id="data-1" class="list-group list-group-flush">
                                    <li class="bg-transparent list-group-item no-icon pl-0" v-for="(item, n) in data" :key="n">
                                        <div v-if="item.user.attendance">
                                            <figure class="avatar float-left mb-0 mx-3">
                                                <img :src="item.user.photo ? item.user.photo : '/images/profile.png'" alt="image" class="w45">
                                                <i v-if="item.user.attendance.type == 1" class="feather-check-circle text-success ml-3"></i>
                                                <i v-if="item.user.attendance.type == 2" class="feather-check-circle text-warning ml-3"></i>
                                            </figure>
                                        </div>
                                        <div v-else>
                                            <figure class="avatar float-left mb-0 mx-3">
                                                <img :src="item.user.photo ? item.user.photo : '/images/profile.png'" alt="image" class="w45">
                                                <i class="feather-slash text-danger ml-3"></i>
                                            </figure>
                                        </div>
                                        <h3 class="fw-700 mb-0 mt-1">
                                            <a class="font-xsss text-grey-900 text-dark d-block">
                                                {{item.user.name}} <span class="text-grey-600">({{item.user.role.name}})</span>
                                            </a>
                                        </h3>
                                        <span class="d-block">{{item.user.email}}</span> 
                                        <a v-if="item.user.attendance" href="javascript:void(0)" @click="setCurrent(item)" class="badge badge-primary text-white badge-pill"><i class="feather-chevron-right"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="current" class="col-md-5">
                            <div class="card shadow-xs rounded-lg border-0 p-4 mb-4">
                                <div class="row mb-3">
                                    <div class="col-12 text-center">
                                        <img :src="current.photo ?  current.photo : '/images/profile.png'" alt="image" class="w-25">
                                    </div>
                                </div>
                                <h4 class="font-md fw-700 text-grey-900 text-center d-block mb-2">{{current.name}}</h4>
                                <h4 class="font-xsss fw-500 text-grey-700 text-center d-block mb-0">{{current.email}}</h4> <hr>
                                <h4 class="font-xsss fw-700 text-grey-900 text-center d-block mb-0">Accumulation</h4> <hr>
                                <div class="row">
                                    <div class="col-12 mb-3 border-bottom pb-3">
                                        <table>
                                            <tr class="font-xsss text-grey-900">
                                                <td>Present</td>
                                                <td>&nbsp; : &nbsp;</td>
                                                <td>{{current.accumulate_present}}</td>
                                            </tr>
                                            <tr class="font-xsss text-grey-900">
                                                <td>Late</td>
                                                <td>&nbsp; : &nbsp;</td>
                                                <td>{{current.accumulate_absent}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-12 text-center">
                                        <div class="row justify-content-around">
                                            <span class="col-12 font-xs text-grey-900 fw-700 mb-3">{{current.attendance.created_at | Date}}</span>
                                            <button v-if="!current.attendance.status" class="col-12 btn btn-lg btn-success" @click="setConfirmation(current.attendance.id)"><i class="fas fa-check"></i></button>
                                            <button v-else class="col-12 btn btn-lg btn-success" disabled><i class="fas fa-thumbs-up"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-12 text-center py-3">
                    <img :src="'/images/empty.png'" alt="" width="300">
                    <h1 class="text-muted">Data don't exist</h1>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12 py-3 text-center">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
    data(){
        return{
            media : process.env.VUE_APP_URL_CLOUD,
            paramsId: this.$route.params.idCourse,
            isLoad: true,
            date: '',
            data: [],
            current: null,
        }
    },
    filters:{
        Date(value) {
            if (value) {
                return moment(String(value)).format('MMMM, Do YYYY - H:m:s')
            }
        }
    },
    created(){
        this.getEverone()
    },
    methods: {
        async getEverone(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/attendance?slug=${this.paramsId}&date=${this.date}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.date = res.data.date
                this.data = res.data.data
                this.isLoad = false
                this.current = null
            })
        },
        setCurrent(data){
            this.current = data.user
        },
        changeDate(){
            this.getEverone()
        },
        async setConfirmation(id){
            var data = {
                id: id,
                slug: this.paramsId
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/attendance/confirm`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if(res.data.success) {
                    this.getEverone()
                    this.$swal({
                        toast: true,
                        title: 'Attendance',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Attendance',
                        text: res.data.message,
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.isactive{
    background-color: #FCC13E;
    color: black;
}
.inactive{
    background-color: white;
    color: black;
}
</style>